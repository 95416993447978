import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layouts/Layout"
import { Section, ContactForm, HeaderRender } from "../components/elements"
import { Grid, Col } from "../components/utilities"

const FormCol = styled(Col)`
  h2 {
    margin-bottom: 10px;
  }
`

const MapBox = styled.div`
  iframe {
    width: 100%;
  }
`

const ContactPage = ({ data }) => {
  const node = data.allWordpressPage.edges[0].node
  return (
    <Layout
      title={node.acf.seo_title}
      description={node.acf.seo_description}
      image={node.featured_media ? node.featured_media.source_url : null}
      bodyRender={() => <HeaderRender>{node.title}</HeaderRender>}
    >
      <Section modifiers={"danger"}>
        <Grid modifiers={["gap"]}>
          <FormCol>
            <h2>Let Us Contact You</h2>
            <p>
              <strong>Get a quote</strong> or{" "}
              <strong>schedule an appointment</strong>
            </p>
            <ContactForm
              postSubmit={() => {
                if (typeof window !== "undefined") {
                  window.location.replace("/thank-you")
                }
              }}
            />
          </FormCol>
          <FormCol>
            <h2>You Contact Us</h2>
            <div dangerouslySetInnerHTML={{ __html: node.content }}></div>
            <MapBox>
              <iframe
                title="Siferds Plumbing, Hearing and Air Conditioning Location"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12028.667228184295!2d-83.6779166!3d41.0872019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8050a3c321e78c70!2sSiferd%20Plumbing%20Heating%20%26%20A%2FC%20LLC!5e0!3m2!1sen!2sus!4v1584025462977!5m2!1sen!2sus"
                height="450"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>
            </MapBox>
          </FormCol>
        </Grid>
      </Section>
    </Layout>
  )
}

export default ContactPage

export const contactQuery = graphql`
  query ContactPageQuery {
    allWordpressPage(filter: { slug: { eq: "contact" } }) {
      edges {
        node {
          id
          title
          content
          path
          featured_media {
            source_url
          }
          acf {
            seo_title
            seo_description
          }
        }
      }
    }
  }
`
